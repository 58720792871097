/**
 * Combined by jsDelivr.
 * Original files:
 * - /npm/notyf@3.10.0/notyf.min.css
 * - /npm/css-tooltip@0.3.4/dist/css-tooltip.min.css
 * - /npm/photoswipe@5.4.4/dist/photoswipe.min.css
 * - /npm/@glidejs/glide@3.7.1/dist/css/glide.core.min.css
 * - /npm/@glidejs/glide@3.7.1/dist/css/glide.theme.min.css
 *
 * Do NOT use SRI with dynamically generated files! More information: https://www.jsdelivr.com/using-sri-with-dynamic-files
 */
